import React, { useState } from "react";
import Layout from "../../components/high-level/layout/layout";
import {
  Button,
  Divider,
  Flex,
  Image,
  Text,
  Title,
  createStyles,
} from "@mantine/core";
import Loading from "../../components/low-level/loading/Loading";
import { useGetPaymentLinks } from "../../api/pay/service";
import { Link } from "react-router-dom";
import { IPaymentLink } from "../../interfaces/payment-links.interface";
import { logEvent } from "../../utils/analytics-service";
import { paymentLinkClickEvent } from "../../utils/log-events";
const Payment = ({ translation }: any) => {
  const { data, isLoading }: any = useGetPaymentLinks();
  const { classes } = useStyles();
  const [boxHover, setBoxHover] = useState(1);

  const handleLinkClick = (
    event: React.MouseEvent<HTMLAnchorElement>,
    paymentLink: IPaymentLink
  ) => {
    logEvent(
      paymentLinkClickEvent(
        paymentLink.id,
        paymentLink.price,
        paymentLink.coins
      )
    );
  };

  if (isLoading || data === undefined) {
    return (
      <Loading
        right={false}
        headerTitle={translation.payment_page.header_title}
        headerFixed={false}
        padding={false}
      />
    );
  }

  return (
    <Layout
      headerFixed={false}
      padding={false}
      right={false}
      headerTitle={translation.payment_page.header_title}
    >
      <div className={classes.container}>
        <Title color="white" align="center" fz={32}>
          {translation.payment_page.title}
        </Title>
        <Text
          fz={14}
          color="white"
          className={classes.info_text}
          mt={5}
          align="center"
        >
          {translation.payment_page.text_1}
        </Text>
        <Text
          fz={14}
          color="white"
          className={classes.info_text}
          align="center"
        >
          {translation.payment_page.text_2}
        </Text>
        <Divider mt={5} mb={5} />
        <Text
          fz={14}
          color="white"
          className={classes.info_text}
          align="center"
        >
          {
            "Für Kontakt zu SegPay und mehr Informationen bitte klicke diesen Link:"
          }
        </Text>
        <Link
          style={{ color: "red" }}
          to={"/my/settings/account"}
          target={"_self"}
        >
          Segpay Contact
        </Link>
        <Text
          fz={14}
          color="white"
          className={classes.info_text}
          align="center"
        >
          {"oder direkt zu segpay.com/contact-us/"}
        </Text>
        <a
          style={{ color: "red" }}
          href="https://www.segpay.com/contact-us/"
          target={"_blank"} rel="noreferrer"
        >
          Segpay
        </a>

        <Flex className={classes.card_area} mt={"10vh"} align="center">
          {data?.urlForCheckouts?.map((x: IPaymentLink, i: number) => (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
              href={x.urlForCheckout}
              // href={undefined}
              target="_blank"
              rel="noreferrer"
              onClick={(event) => handleLinkClick(event, x)}
              key={i}
              className={`${classes.card} ${
                (i === 0 && classes.card_yellow) ||
                (i === 1 && classes.card_red) ||
                (i === 2 && classes.card_pink)
              } ${boxHover === i && classes.card_hover}`}
              onMouseEnter={() => setBoxHover(i)}
              onMouseLeave={() => setBoxHover(1)}
            >
              <Flex direction="row" align="center" style={{ height: "30%" }}>
                <Image
                  width={45}
                  height={45}
                  src={require("../../assets/star.png")}
                />
                <Title fz={50} ml={8} color="white">
                  {x.coins}
                </Title>
              </Flex>
              <Flex
                direction="column"
                justify="center"
                style={{ height: "30%" }}
              >
                <Title fz={30} color="white" align="center">
                  {x.currency === "USD" && "$ " + x.price}
                </Title>
                <Text color="white">{x.title}</Text>
              </Flex>
              <Flex
                direction="column"
                justify="space-around"
                align="center"
                style={{
                  height: "25%",
                  width: "100%",
                }}
              >
                <div
                  style={{ height: 1, width: "100%", backgroundColor: "white" }}
                />
                <Text mb={"2vh"} color="white">
                  {x.description}
                </Text>
              </Flex>
              <Flex
                align="center"
                justify="center"
                style={{ height: "15%", width: "100%" }}
              >
                <Button
                  style={{
                    backgroundColor: boxHover === i ? "#D2E951" : "white",
                  }}
                  className={classes.buy_button}
                >
                  {translation.payment_page.buy}
                </Button>
              </Flex>
            </a>
          ))}
        </Flex>

        {/* <div className={classes.logo_area}>
          <div className={classes.divider} />
          <Flex
            direction="row"
            mt={"4vh"}
            align="center"
            justify="center"
            gap={19}
          >
            {paymentDetail?.map((x: any, i: number) => (
              <img
                key={i}
                src={`data:image/jpeg;base64,${x.attributes.providerInformation.logo}`}
                className={classes.payment_provider_image}
              />
            ))}
          </Flex>
        </div> */}
      </div>
    </Layout>
  );
};
const useStyles = createStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    height: "calc(100vh - 50px)",
    [`@media (max-width: 768px)`]: {
      marginTop: 100,
      height: "fit-content",
    },
  },
  card_area: {
    flexDirection: "row",
    height: "45vh",
    [`@media (max-width: 768px)`]: {
      flexDirection: "column",
      height: "fit-content",
    },
  },
  card: {
    height: "45vh",
    width: "15vw",
    display: "flex",
    textDecoration: "none",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    borderRadius: 20,
    padding: "12px 22px",
    transition: "all .4s",

    [`@media (max-width: 1440px)`]: {
      width: "18vw",
    },
    [`@media (max-width: 768px)`]: {
      width: "70vw",
      marginBottom: 70,
      marginRight: "0px !important",
      marginLeft: "0px !important",
      height: "40vh",
      "&:hover": {
        transform: "scale(1.05)",
        transition: "all .8s",
        boxShadow: "0px 6px 17px 10px rgba(0, 0, 0, 0.19)",
        zIndex: 99,
      },
    },
  },
  card_hover: {
    transform: "scale(1.2)",
    transition: "all .8s",
    zIndex: 99,
  },
  info_text: {
    width: "45%",
    [`@media (max-width: 1280px)`]: {
      width: "65% !important",
    },
  },
  buy_button: {
    width: "80%",
    height: 50,
    backgroundColor: "white",
    borderRadius: 50,
    color: "black",
  },
  payment_provider_image: {
    width: "152px !important",
  },
  divider: {
    backgroundColor: "#BCBCBC",
    width: "60vw",
    height: 1,
    opacity: 0.44,
    [`@media (max-width: 768px)`]: {
      width: "100% !important",
    },
  },
  card_yellow: {
    marginRight: 14,
    background: "linear-gradient(90deg, #D2E951 0%, #799F0C 100%)",
    animation: "wavegradientYellow 4s linear infinite alternate both",
    "@keyframes wavegradientYellow": {
      "0%": {
        border: "1px solid black",
        boxShadow: "0px 6px 10px 10px #D2E951",
      },
      "100%": {
        border: "0px solid #799F0C",
        boxShadow: "0px -5px 15px 15px #799F0C",
      },
    },
  },
  card_red: {
    marginRight: 14,
    marginLeft: 14,
    background: "linear-gradient(90deg, #FE8C00 0%, #F83600 100%)",
    animation: "wavegradientRed 2s linear infinite alternate both",
    "@keyframes wavegradientRed": {
      "0%": {
        border: "1px solid black",
        boxShadow: "0px 6px 17px 10px #FE8C00",
      },
      "100%": {
        border: "0px solid #799F0C",
        boxShadow: "0px 0px 20px 15px #F83600",
      },
    },
  },
  card_pink: {
    marginLeft: 14,
    background: "linear-gradient(90deg, #F6895E 0%, #F9AB81 100%)",
    animation: "wavegradientPink 6s linear infinite alternate both",
    "@keyframes wavegradientPink": {
      "0%": {
        border: "1px solid black",
        boxShadow: "0px 0px 10px 10px #F6895E",
      },
      "100%": {
        border: "0px solid #799F0C",
        boxShadow: "0px 10px 15px 15px #F9AB81",
      },
    },
  },
  logo_area: {
    marginTop: "10vh",
    width: "80%",
    [`@media (max-width: 768px)`]: {
      marginTop: "0vh",
    },
  },
}));

export default Payment;
