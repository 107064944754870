import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import {
  LoginPage,
  RegisterPage,
  ForgotPasswordPage,
  ResetPasswordPage,
} from "../../../pages/AuthPages";
import CreateCreatorPage from "../../../pages/CreateCreatorPage/CreateCreatorPage";
// import CreatorProfilePage from "../../../pages/CreatorProfilePage/CreatorProfilePage";
import CreatorProfilePage from "../../../pages/CreatorProfilePage/index";
import FeedPage from "../../../pages/FeedPage/FeedPage";
import NewPostPage from "../../../pages/NewPostPage/NewPostPage";
import EditProfilePage from "../../../pages/ProfilePage/EditProfilePage";
import ProfilePage from "../../../pages/ProfilePage/ProfilePage";
import SettingsPage from "../../../pages/SettingsPage/SettingsPage";
import LegalNoticePage from "../../../pages/LegalPages/LegalNoticePage";
import PaymentPage from "../../../pages/PaymentPage/Payment";

import { ProtectedLayout } from "../ProtectedLayout/ProtectedLayout";
import { UnprotectedLayout } from "../UnprotectedLayout/UnprotectedLayout";

import { AnimatePresence } from "framer-motion";
import LandingPage from "../../../pages/LandingPage/LandingPage";
import CreatorLandingPage from "../../../pages/CreatorLandingPage/CreatorLandingPage";
import AgeControl from "../modal/ageControl";
import CookiePermission from "../modal/cookiePermission";
import TermsPage from "../../../pages/LegalPages/TermsPage";
import DatenschutzPage from "../../../pages/LegalPages/DatenschutzPage";
import PostEditPage from "../../../pages/PostEditPage/PostEditPage";
import ChatPage from "../../../pages/ChatPage/ChatPage";
import StatisticsPage from "../../../pages/StatisticsPage/StatisticsPage";
import EventsPage from "../../../pages/EventsPage/EventPage";
import CreateEventPage from "../../../pages/CreateEventPage/CreateEventPage";
import ComplaintsFormPage from "../../../pages/ComplaintsFormPage/ComplaintsFormPage";

const AnimatedRoutes = ({ user, translation }: any) => {
  const location = useLocation();

  return (
    <AnimatePresence>
      <AgeControl translation={translation} key={1} />
      <CookiePermission translation={translation} key={2} />

      <Routes location={location} key={location.pathname}>
        <Route
          element={<ProtectedLayout translation={translation} user={user} />}
        >
          <Route
            path="creator/create"
            element={<CreateCreatorPage translation={translation} />}
          />
          <Route
            path="post/create"
            element={<NewPostPage translation={translation} />}
          />
          <Route path="/my">
            <Route path="/my" element={<Navigate to="/my/feed" replace />} />
            <Route
              path="feed"
              element={<FeedPage translation={translation} />}
            />
            <Route
              path="profile"
              element={<ProfilePage translation={translation} />}
            />
            <Route
              path="statistics/:statistics_name?"
              element={<StatisticsPage translation={translation} />}
            />
            <Route
              path="post/edit/:id"
              element={<PostEditPage translation={translation} />}
            />
            <Route
              path="profile/edit"
              element={<EditProfilePage translation={translation} />}
            />
            <Route
              path="settings"
              element={<SettingsPage translation={translation} />}
            />
            <Route
              path="settings/:setting_name"
              element={<SettingsPage translation={translation} />}
            />
            <Route
              path="settings/:setting_name/:right_menu"
              element={<SettingsPage translation={translation} />}
            />
            {/* <Route path="chat" element={<ChatPage />} /> */}
            {/* <Route
              path="chat"
              element={<ChatPageAlternative translation={translation} />}
            /> */}

            <Route
              path="chat/:id?"
              element={<ChatPage translation={translation} />}
            />
            <Route
              path="create-event"
              element={<CreateEventPage translation={translation} />}
            />
            <Route
              path="events/:id?"
              element={<EventsPage translation={translation} />}
            />
            <Route
              path="payment"
              element={<PaymentPage translation={translation} />}
            />
            
          </Route>
          {/* <Route
            path=":creator_name"
            element={<CreatorProfilePage translation={translation} />}
          /> */}
        </Route>

        <Route path="/" element={<UnprotectedLayout user={user} />}>
          <Route path="/" element={<LandingPage translation={translation} />} />
          <Route
            path="/creators"
            element={<CreatorLandingPage translation={translation} />}
          />
          <Route
            path="/login"
            element={<LoginPage translation={translation} />}
          />
          <Route
            path="/register"
            element={<RegisterPage translation={translation} />}
          />
          <Route
            path="/forgot-password"
            element={<ForgotPasswordPage translation={translation} />}
          />
          <Route
            path="/reset-password"
            element={<ResetPasswordPage translation={translation} />}
          />
        </Route>
        <Route
          path="/legalnotice"
          element={<LegalNoticePage translation={translation} />}
        />
        <Route
          path="/:creator_name"
          element={<CreatorProfilePage translation={translation} />}
        />
        <Route
          path="/privacy"
          element={<DatenschutzPage translation={translation} />}
        />
        <Route
          path="/terms"
          element={<TermsPage translation={translation} />}
        />
         <Route
          path="/complaints-form"
          element={<ComplaintsFormPage translation={translation} />}
        />
      </Routes>
    </AnimatePresence>
  );
};

export default AnimatedRoutes;
